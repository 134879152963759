
export enum HarvestSeedPropsGrowing {
  Easy = 1,
  Normal = 2,
  Hard = 3,
}

export const HarvestSeedPropsGrowingTr: Record<HarvestSeedPropsGrowing, string> = {
  [HarvestSeedPropsGrowing.Easy]: 'universal_harvest_growing_easy',
  [HarvestSeedPropsGrowing.Normal]: 'universal_harvest_growing_normal',
  [HarvestSeedPropsGrowing.Hard]: 'universal_harvest_growing_hard',
};


export enum HarvestSeedPropsResistance {
  Weak = 1,
  Neutral = 2,
  Strong = 3,
}

export const HarvestSeedPropsResistanceTr: Record<HarvestSeedPropsResistance, string> = {
  [HarvestSeedPropsResistance.Weak]: 'universal_harvest_resistance_weak',
  [HarvestSeedPropsResistance.Neutral]: 'universal_harvest_resistance_neutral',
  [HarvestSeedPropsResistance.Strong]: 'universal_harvest_resistance_strong',
};